import React from "react";
import NavBar from "../components/NavBar";

import TableData from "../components/TableData";



export default function MainPage() {
    return (
        <>
            <NavBar />
            <TableData />

        </>
    )
};